export function resolver<T = any, R = any>(
  path?: string,
  defaultVal?: R,
): (item: T) => R {
  return (item) => resolve<T, R>(item, path) ?? defaultVal!
}

export function resolve<T = any, R = any>(item: T, path?: string): R {
  if (!path) return item as unknown as R
  return path
    .split('/')
    .reduce(
      (branch, path) =>
        branch && typeof branch === 'object'
          ? (branch as any)[path]
          : undefined,
      item,
    ) as never
}
