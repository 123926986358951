import { resolver } from './resolver'

export type Sorter<T> = (a: T, b: T) => number
export function sorter<T = any>(
  resolve?: ((item: T) => string | number) | string,
): Sorter<T> {
  const resolveCoerced =
    typeof resolve !== 'function' ? resolver(resolve) : resolve
  return (a, b) => {
    const aVal = resolveCoerced(a)
    const bVal = resolveCoerced(b)
    return aVal > bVal ? 1 : aVal < bVal ? -1 : 0
  }
}
