import React from 'react'
import Link from 'next/link'

import { PortraitIcon, MailIcon, PhoneIcon } from './icons'

type ContactProps = {
  person: any
  wrapperCssClasses?: string
  children: React.ReactNode
}

const Contact = ({ person, wrapperCssClasses, children }: ContactProps) => {
  return (
    <div
      className={`w-full min-w-48 flex-initial sm:w-1/2 lg:w-1/3 xl:w-1/4 ${wrapperCssClasses}`}
    >
      <Link href={`/contact-partners/${person.activeDirectoryId}`}>
        <div className="tbf-hover flex h-full flex-col bg-white focus:bg-red-500 focus:outline-none">
          <div className="h-full bg-white">
            {children}
            <div className="p-5 pb-1">
              <div className="text-xl font-medium leading-tight">
                {person.firstName} {person.lastName}
              </div>
            </div>

            <div className="mb-3 ml-4 mr-2 flex flex-wrap items-end justify-between text-red-500">
              <div className="mt-2">
                <div className="flex items-center text-sm">
                  <MailIcon className="mr-1 h-4 w-4" />
                  <span className="text-red-500">{person.email}</span>
                </div>
                <div className="flex items-center text-sm">
                  <PhoneIcon className="mr-1 h-4 w-4" />
                  <span className="flex-none text-red-500">{person.phone}</span>
                </div>
              </div>
              <div className="ml-auto mt-2">
                <PortraitIcon className="h-6 w-6" />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Contact
