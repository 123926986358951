import React from 'react'

import { ChevronDown } from './icons'

type SelectInputProps = {
  id: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  children: React.ReactNode
}

const SelectInput = ({ id, value, onChange, children }: SelectInputProps) => {
  return (
    <div className="focus:bg-red-10 relative hover:bg-red-100">
      <select
        id={id}
        className="relative z-10 w-full cursor-pointer appearance-none border border-red-500 bg-transparent py-1 pl-4 pr-10 text-red-500 outline-none"
        value={value}
        onChange={onChange}
      >
        {children}
      </select>
      <div className="absolute inset-y-0 right-0 flex items-center bg-red-500 px-3">
        <ChevronDown className="icon-fill-white h-4" />
      </div>
    </div>
  )
}

export default SelectInput
