import { useMemo } from 'react'
import Fuse from 'fuse.js'

type UseFuse = <T>(items: T[], filterText: string, config: any) => { item: T }[]

const useFuse: UseFuse = (items, filterText, config) => {
  const fuseConfig = useMemo(() => ({ threshold: 0.5, ...config }), [config])

  const fuse = useMemo(() => new Fuse(items, fuseConfig), [items, fuseConfig])

  const filteredItems = useMemo(
    () =>
      filterText !== ''
        ? fuse.search(filterText)
        : items.map((item) => ({ item: item })),
    [fuse, items, filterText],
  )

  return filteredItems
}

export default useFuse
