import type { Advisor } from '@/.generated/tbfTypes/graphql'
import type {
  ContactPartnersQuery,
  LocaleString,
} from '@/.generated/sanityTypes/graphql'
import { sorter } from '@monorepo/helper/utils/sorting'

import React, { useState, useMemo, useContext } from 'react'

import { useDebounce } from 'use-debounce'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'

import useFuse from '@monorepo/helper/hooks/useFuse'
import { referenceMap } from '@monorepo/helper/utils/referenceMap'
import SelectInput from '@/components/selectInput'
import Card from '@/components/card'
import { SearchIcon } from '@/components/icons'
import Contact from '@/components/contact'
import { getTypedLocale } from '../lib/typeHelper'
import { AllTrustedAdvisorsContext } from '@/lib/advisorContext'
import type { ImageUrlList } from '@/lib/next.tbf-helper'

const isLocaleString = (obj: any): obj is LocaleString =>
  obj?.de && obj?.en && obj?.fr && obj?.it
const ifLocaleString = (obj: any) => (isLocaleString(obj) ? obj : {})

const languageToGerman = {
  de: 'Deutsch',
  en: 'Englisch',
  fr: 'Französisch',
  it: 'Italienisch',
  any: 'any',
} as const

const labelValueLanguage = 'de'

const filterPersonBy =
  (key: 'languages' | 'services' | 'domains', searchTerm: string) =>
  (props?: { item?: Advisor | null }) => {
    if (!searchTerm || searchTerm === 'any' || !props?.item) {
      return props
    } else {
      if (key === 'languages')
        return props?.item?.[key]
          ?.map((s) => s?.[labelValueLanguage]?.toUpperCase())
          ?.includes(searchTerm.toUpperCase())
      return (
        props?.item?.[key]
          // TOOD fix this type issue
          // @ts-ignore
          ?.map((s) => referenceMap?.[labelValueLanguage]?.[s]?.toUpperCase())
          ?.includes(searchTerm?.toUpperCase())
      )
    }
  }

const initialFilterState = {
  searchQuery: '',
  service: 'any',
  domain: 'any',
  language: 'any',
}

// MAIN COMPONENT

type TrustedAdvisorsListProps = {
  data: ContactPartnersQuery & {
    allEmployeesImageURLs: ImageUrlList
  }
  className?: string
}
const TrustedAdvisorsList = ({ data, className }: TrustedAdvisorsListProps) => {
  const locale = getTypedLocale(useRouter().locale)
  const t = useTranslations()
  const trustedAdvisors = useContext(AllTrustedAdvisorsContext) || []
  const [filter, setActualFilter] = useState(initialFilterState)
  const [debouncedSearchQuery] = useDebounce(filter.searchQuery, 100)
  const {
    allDomain: domains,
    allService: services,
    allEmployeesImageURLs: employeesImagURLs,
  } = data

  const sortedDomains = useMemo(
    () => domains?.sort(sorter(`title/${locale}`)),
    [domains, locale],
  )
  const sortedServices = useMemo(
    () => services?.sort(sorter(`title/${locale}`)),
    [services, locale],
  )

  const setFilter = (filterName: keyof typeof initialFilterState) => {
    return (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) =>
      setActualFilter({ ...filter, [filterName]: e.target.value })
  }

  const fuseOptions = { keys: ['firstName', 'lastName', 'email', 'fullName'] }
  const searchedItems = useFuse(
    trustedAdvisors,
    debouncedSearchQuery,
    fuseOptions,
  )

  const filteredItems = searchedItems
    .filter(
      filterPersonBy(
        'languages',
        languageToGerman[filter.language as keyof typeof languageToGerman],
      ),
    )
    .filter(filterPersonBy('services', filter.service))
    .filter(filterPersonBy('domains', filter.domain))

  const getPictureByAlias = (alias: any) =>
    employeesImagURLs.find((employee) => employee.alias == alias)

  return (
    <>
      <Card className={className}>
        <form
          className="mx-auto max-w-3xl text-xl"
          onSubmit={(event: any) => {
            event.preventDefault()
          }}
        >
          <label htmlFor="search-query" className="mt-5 block pl-4">
            {t('advisors.search')}
          </label>
          <div className="relative mt-2 border border-red-500 hover:bg-red-100 focus:bg-red-100 focus:outline-none">
            <input
              type="text"
              id="search-query"
              autoComplete="off"
              className="w-full py-1 pl-10 text-red-500 outline-none hover:bg-red-100 focus:bg-red-100"
              value={filter.searchQuery}
              onChange={setFilter('searchQuery')}
            />
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
              <SearchIcon className="pointer-events-none h-6 w-6 fill-current text-red-500" />
            </div>
          </div>

          <label htmlFor="search-field-of-activity" className="mt-5 block pl-4">
            {t('advisors.fieldOfActivity')}
          </label>
          <div className="mt-2">
            <SelectInput
              id="search-field-of-activity"
              value={filter.domain}
              onChange={setFilter('domain')}
            >
              <option value="any">{t('common.all')}</option>
              {sortedDomains.map((domain) => (
                <option
                  key={domain._id}
                  value={
                    ifLocaleString(domain?.title)?.[labelValueLanguage] || ''
                  }
                >
                  {ifLocaleString(domain?.title)?.[locale]}
                </option>
              ))}
            </SelectInput>
          </div>

          <label htmlFor="search-services" className="mt-5 block pl-4">
            {t('advisors.service')}
          </label>
          <div className="mt-2">
            <SelectInput
              id="search-services"
              value={filter.service}
              onChange={setFilter('service')}
            >
              <option value="any">{t('common.all')}</option>
              {sortedServices.map((service) => (
                <option
                  key={service._id}
                  value={
                    ifLocaleString(service?.title)?.[labelValueLanguage] || ''
                  }
                >
                  {ifLocaleString(service?.title)?.[locale]}
                </option>
              ))}
            </SelectInput>
          </div>

          <label htmlFor="search-language" className="mt-5 block pl-4">
            {t('advisors.language')}
          </label>
          <div className="mt-2">
            <SelectInput
              id="search-language"
              value={filter.language}
              onChange={setFilter('language')}
            >
              <option value="any">{t('common.all')}</option>
              <option value="de">Deutsch</option>
              <option value="fr">Français</option>
              <option value="en">English</option>
              <option value="it">Italiano</option>
            </SelectInput>
          </div>
        </form>
      </Card>

      {trustedAdvisors && (
        <Card noStyles noPadding className="flex flex-wrap">
          {filteredItems.map(({ item: person }) => {
            if (!person) return null
            const employeePictureUrl = getPictureByAlias(
              person.email?.split('@')[0],
            )?.imageUrl
            const fullName = `${person?.firstName ?? ''} ${
              person?.lastName ?? ''
            }`

            return (
              <Contact
                person={person}
                key={person.activeDirectoryId}
                wrapperCssClasses="pb-5 sm:p-5 mx-6 sm:mx-0"
              >
                {employeePictureUrl ? (
                  <div className="relative aspect-square w-full">
                    <Image
                      sizes="(min-width: 1280px) 280px, (min-width: 1040px) 33vw, (min-width: 640px) 50vw , 100vw"
                      alt={fullName}
                      src={employeePictureUrl}
                      loading="lazy"
                      fill
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                ) : (
                  <div className="aspect-square w-full bg-gray-500 opacity-25"></div>
                )}
              </Contact>
            )
          })}
        </Card>
      )}
    </>
  )
}

export default TrustedAdvisorsList
