export const referenceMap = {
  de: {
    application_area_64: 'Abfallverwertung',
    application_area_65: 'Abwasserbehandlung',
    application_area_66: 'Automation',
    application_area_67: 'Bahnbau',
    application_area_68: 'Bahntechnik',
    application_area_69: 'Elektrotechnik',
    application_area_70: 'Energie',
    application_area_71: 'Gebäudetechnik',
    application_area_72: 'Hochbau',
    application_area_73: 'Klärschlammverwertung',
    application_area_74: 'Naturgefahren',
    application_area_75: 'Tiefbau',
    application_area_76: 'Umweltschutz',
    application_area_77: 'Verkehrsplanung',
    application_area_78: 'Verkehrstechnik',
    application_area_79: 'Raumplanung',
    application_area_100: 'Verkehr',
    application_area_110: 'Bahn',
    application_area_120: 'Elektrotechnik und Automation',
    application_area_130: 'Offen für Neues',
    application_area_140: 'Informatik',
    application_area_150: 'Nachhaltigkeit',
    service_91: 'Planung / Projektierung',
    service_92: 'Bauleitung',
    service_93: 'Expertise',
    service_94: 'Projektmanagement',
    service_95: 'GPL / OBL / BHU',
    service_96: 'Projektentwicklung',
    service_97: 'Unternehmensberatung',
    service_98: 'Engineering',
    service_1001: 'Gesamtprojektleitung',
    service_1002: 'Oberbauleitung',
    service_1003: 'Bauherrenunterstützung',
    service_1004: 'Bildung',
  },
  en: {
    application_area_64: 'Waste recycling',
    application_area_65: 'Wastewater treatment',
    application_area_66: 'Atomation',
    application_area_67: 'Railway construction',
    application_area_68: 'Railway engineering',
    application_area_69: 'Electrical engineering',
    application_area_70: 'Energy',
    application_area_71: 'Building services engineering',
    application_area_72: 'Structural engineering',
    application_area_73: 'Sewage sludge processing',
    application_area_74: 'Natural hazards',
    application_area_75: 'Civil engineering',
    application_area_76: 'Environmental protection',
    application_area_77: 'Transport planning',
    application_area_78: 'Traffic engineering',
    application_area_79: 'Spatial planning',
    application_area_100: 'Traffic',
    application_area_110: 'Bahn',
    application_area_120: 'Electrical Engineering and Automation',
    application_area_130: 'Open for New',
    application_area_140: 'Computer Science',
    application_area_150: 'Sustainability',
    service_91: 'Planning / project engineering',
    service_92: 'Construction management',
    service_93: 'Expertise',
    service_94: 'Project management',
    service_95: 'GPL / OBL / BHU',
    service_96: 'Project development',
    service_97: 'Management Consulting',
    service_98: 'Engineering',
    service_1001: 'Overall project management',
    service_1002: 'overall project management',
    service_1003: 'Owner support',
    service_1004: 'Education',
  },
  fr: {
    application_area_64: 'Valorisation des déchets',
    application_area_65: 'Traitment des eaux usées',
    application_area_66: 'Automatisation',
    application_area_67: 'Génie ferroviare',
    application_area_68: 'Technique ferroviare',
    application_area_69: 'Installation électrique',
    application_area_70: 'Energie',
    application_area_71: 'Technique du bâtiment',
    application_area_72: 'Bâtiment',
    application_area_73: 'Valorisations des boues de STEP',
    application_area_74: 'Dangers naturels',
    application_area_75: 'Génie civil',
    application_area_76: "Protection de l'environnement",
    application_area_77: 'Plantification des transports',
    application_area_78: 'Technique routière',
    application_area_79: 'Planification urbaine',
    application_area_100: 'Transports',
    application_area_110: 'Bahn',
    application_area_120: 'Électrotechnique et automatisation',
    application_area_130: 'Ouvert à la nouveauté',
    application_area_140: 'Informatique',
    application_area_150: 'Développement durable',
    service_91: 'Planification / étude de projet',
    service_92: 'Direction des travaux',
    service_93: 'Expertise',
    service_94: 'Gestion de projets',
    service_95: 'GPL / OBL / BHU',
    service_96: 'Conception de projets',
    service_97: "Conseil en gestion d'entreprise",
    service_98: 'Ingénierie spécialisée',
    service_1001: 'Directeur de projet',
    service_1002: 'Directeur de chantier',
    service_1003: "Assistance au Maître d'ouvrage",
    service_1004: 'Formation',
  },
  it: {
    application_area_64: 'Trattamento dei rifiuti',
    application_area_65: 'Trattamento acque',
    application_area_66: 'Automazione',
    application_area_67: 'Costruzioni ferroviarie',
    application_area_68: 'Impiantistica ferroviaria',
    application_area_69: 'Impianti elettrici',
    application_area_70: 'Energia',
    application_area_71: 'Impianti tecnici edili',
    application_area_72: 'Costruzioni edili',
    application_area_73: 'Trattamento termico dei fanghi di depurazione',
    application_area_74: 'Pericoli naturali',
    application_area_75: 'Genio civile',
    application_area_76: "Protezione dell'ambiente",
    application_area_77: 'Progettazione stradale',
    application_area_78: 'Impiantistica stradale',
    application_area_79: 'Pianificazione territoriale',
    application_area_100: 'Traffico',
    application_area_110: 'Bahn',
    application_area_120: 'Ingegneria elettrica e automazione',
    application_area_130: 'Open for New',
    application_area_140: 'Computer Science',
    application_area_150: 'Sostenibilità',
    service_91: 'Pianificazione / ingegneria di progetto',
    service_92: 'Direzione dei lavori',
    service_93: 'Consulenza',
    service_94: 'Project management',
    service_95: 'DGP / DGL / SaC',
    service_96: 'Sviluppo del progetto',
    service_97: 'Consulenza manageriale',
    service_98: 'Ingegneria specializzata',
    service_1001: 'Responsabile progetto',
    service_1002: 'Direttore generale dei lavori',
    service_1003: 'Supporto alla Committenza',
    service_1004: 'Formazione',
  },
}
