import type {
  AllTrustedAdvisorsQuery,
  AllTrustedAdvisorsQueryVariables,
} from '@/.generated/tbfTypes/graphql'
import type { GetStaticPropsContext, GetStaticPropsResult } from 'next/types'
import type {
  ContactPartnersQuery,
  ContactPartnersQueryVariables,
  AllPagesQuery,
  Image,
  LocaleString,
} from '@/.generated/sanityTypes/graphql'

import React from 'react'
import { useRouter } from 'next/router'

import Layout from '@/components/layout'
import Card from '@/components/card'
import TrustedAdvisorsList from '@/components/trustedAdvisorsList'
import { contactPartners as contactPartnersQuery } from '@/pages/contact-partners.sanity.graphql'
import { allTrustedAdvisors as allTrustedAdvisorsQuery } from '@/components/trustedAdvisorsList.tbf.graphql'
import { request } from '@/lib/sanityClient'
import { request as requestTbfApi } from '@/lib/tbfClient'
import { isLocalString } from '../lib/typeHelper'
import { getAllPages, getPageQueryVariables } from '@/lib/query'
import { AllTrustedAdvisorsContext } from '@/lib/advisorContext'
import type { ImageUrlList } from '@/lib/next.tbf-helper'
import { getEmployeesImageURLs } from '@/lib/next.tbf-helper'

type TrustedAdvisorsProps = {
  data: ContactPartnersQuery & {
    allEmployeesImageURLs: ImageUrlList
  }
  advisorsData: AllTrustedAdvisorsQuery['trustedAdvisors']
  messages: unknown
  isPreview: boolean
  allPages: AllPagesQuery
}

const TrustedAdvisors = ({
  data,
  advisorsData,
  isPreview,
  allPages,
}: TrustedAdvisorsProps) => {
  const { locale = 'de' } = useRouter()
  const page = data.allPage[0]

  return (
    <Layout
      allPages={allPages}
      isPreview={isPreview}
      title={
        (isLocalString(page?.pageHeader?.sectionTitle) &&
          page?.pageHeader?.sectionTitle[locale as keyof LocaleString]) ||
        undefined
      }
      headerFiles={
        [
          ...(page?.image ? [page?.image as Image] : []),
          ...(page?.additionalImages || []),
        ] as Image[]
      }
    >
      <Card headerData={page.pageHeader} />
      <div className="-mt-12 sm:-mt-20">
        <AllTrustedAdvisorsContext.Provider value={advisorsData}>
          <TrustedAdvisorsList data={data} />
        </AllTrustedAdvisorsContext.Provider>
      </div>
    </Layout>
  )
}

export async function getStaticProps(
  context: GetStaticPropsContext,
): Promise<GetStaticPropsResult<TrustedAdvisorsProps>> {
  const isPreview = context.preview || false
  const queryVariables = getPageQueryVariables('contact-partners')
  const pageData = await request<
    ContactPartnersQuery,
    ContactPartnersQueryVariables
  >(contactPartnersQuery, queryVariables, isPreview)
  const advisorsData = await requestTbfApi<
    AllTrustedAdvisorsQuery,
    AllTrustedAdvisorsQueryVariables
  >(allTrustedAdvisorsQuery)

  if (pageData.errors)
    throw new Error(`graphql Error occured: ${pageData.errors[0].message}`)

  const employeeAlias = advisorsData.data.trustedAdvisors?.map(
    (advisor) => advisor?.email?.split('@')[0],
  )
  const filteredEmployeeAlias = employeeAlias?.filter(Boolean) as string[]
  const allEmployeesImageURLs = await getEmployeesImageURLs(
    filteredEmployeeAlias,
  )

  return {
    props: {
      data: { ...pageData.data, allEmployeesImageURLs },
      advisorsData: advisorsData.data.trustedAdvisors,
      messages: (await import(`@/translation/${context.locale}.json`)).default,
      isPreview,
      allPages: await getAllPages(),
    },
    revalidate: 60 * 30, // once every 30 minutes
  }
}

export default TrustedAdvisors
